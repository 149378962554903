import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
// import HowToInstall from "../../components/Index/HowToInstall"
import ReactNotification from 'react-notifications-component'
import Drift from 'react-driftjs'
import useSiteMetadata from "../../components/SiteMetaData"
import HowToUse from "../../components/Index/HowToUse"
import VideoTutorial from "../../components/Index/VideoTutorial"

const TutorialPage = () => {
  const {driftApp} = useSiteMetadata()
  return(
    <Layout>
      <ReactNotification />
      <SEO title="Tutorial" path="/tutorial"/>
      <HowToUse />
      <VideoTutorial />
    </Layout>
  )
}

export default TutorialPage
